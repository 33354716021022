import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import HttpsRedirect from 'react-https-redirect';
import $ from 'jquery';
import Header from './components/header/header.js';
import Banner from './components/banner/banner.js';
import Dojo from './components/dojo/dojo.js';
import Location from './components/location/location.js';
import Seminars from './components/seminars/seminars.js';
import Gallery from './components/gallery/gallery.js';
import Footer from './components/footer/footer.js';


import './css/site.css';
import './css/site.ie11.css';
import './css/site.rtl.css';
import './fonts/fontawesome-pro-5.0.8/css/fontawesome-all.min.css';

export default class App extends Component {
    static displayName = App.name;

    componentDidMount() {
        if (isMobile) {
            document.documentElement.classList.add('is-mobile-device');
        }

        if (/*@cc_on!@*/false || !!document.documentMode) {
            //console.log("IS IE 11");
            document.documentElement.classList.add('is-ie11');
        }

        $(document).ready(function () {
            let dojo = $("#dojo").offset().top;
            let location = $("#location").offset().top;
            let seminars = $("#seminars").offset().top;
            let gallery = $("#gallery").offset().top;

            $('.navbar-nav a').on("click", function () {
                let target = $(this).attr("jump-target");
                let offset = $(target).offset().top;
                if (offset > 40)
                    offset -= 40;

                $([document.documentElement, document.body]).animate({ scrollTop: offset });
            });

            $(window).scroll(function () {
                let offset = $(this).scrollTop();
                if (dojo - 50 < offset && offset < location - 50) {
                    $(".navbar-nav li.active").removeClass("active");
                    $("#dojo-menu-item").removeClass("active").addClass("active", { duration: 1000 });
                }
                if (location - 50 < offset && offset < seminars - 50) {
                    $(".navbar-nav li.active").removeClass("active");
                    $("#location-menu-item").removeClass("active").addClass("active", { duration: 1000 });
                }

                if (seminars - 50 < offset && offset < gallery - 50) {
                    $(".navbar-nav li.active").removeClass("active");
                    $("#seminars-menu-item").removeClass("active").addClass("active", { duration: 1000 });
                }

                if (gallery - 50 < offset) {
                    $(".navbar-nav li.active").removeClass("active");
                    $("#gallery-menu-item").removeClass("active").addClass("active", { duration: 1000 });
                }
            });
        });
    }

    render() {
        return (
            <div>
                <Header />
                <Banner />
                <Dojo />
                <Location />
                <Seminars />
                <Gallery />
                <Footer />
            </div>
        );
    }
}
