import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container } from 'reactstrap';

import './footer.css';
import './footer.mobile.css';

class Footer extends Component {
    static displayName = Footer.name;

    constructor(props) {
        super(props);
    }

    dan6() {
        if (this.props.i18n.language == "he")
            return <span> {this.props.t("Dan")} 6 </span>;
        else
            return <span>6 <sup style={{ marginLeft: "-4px", color: "#bbb" }} ><small>th</small></sup> {this.props.t("Dan")} </span>;
    }

    render()
    {
        const { t, i18n } = this.props;

        return (
            <section id="footer">
                <Container>
                    <div className="column-3 sensei-dan">
                        <h3>{t("ChiefInstructor")}</h3>
                        <p>
                            <span className="blue">{t("YossiHatzorSensei")}</span><br/>
                            {this.dan6()}
                            <span> , Shidoin </span>
                        </p>
                    </div>
                    <div className="column-3">
                        <h3>{t("Schedule")}</h3>
                        <p>
                            <span className="blue">{t("Sunday")}:</span> 17:00 - 18:30 <br/>
                            <span className="blue">{t("Tuesday")}:</span> 17:00 - 18:30 <br/>
                            <span className="blue">{t("Thursday")}:</span> 17:00 - 18:30 <br/>
                        </p>
                    </div>
                    <div className="column-3">
                        <h3>{t("ContactUs")}</h3>
                        <p>
                            {t("Tel")}: 08-6461789/90 - {t("SportsCenter")} <br/>
                            {t("Tel")}: 052-852-7181 - {t("EitanBehar")} <br/>
                            {t("Email")}: <a href="mailto:info@aikido-beersheva.net">info@aikido-beersheva.net</a>
                        </p>
                    </div>
                </Container>

                <Container style={{display:"flex"}}>
                    <div className="copyright">Copyright © 2021 Beer-Sheva Aikikai</div>
                </Container>
            </section>
        );
    }
}

export default withTranslation()(Footer);
