const Strings_Ru = {
    Aikido: "Айкидо",
    AikidoSince:"Айкидо в Беэр- Шеве с 1993 года",
    AssistantInstructor: "Помощник инструктора",
    BeerShevaAikikai: "Беер Шева Айкикай",
    BerkeleyMemberOf: "Un En Kai напрямую связано с #0# (Aikikai Foundation), Токио, Япония",
    ByEmail: "э.почта",
    ByPhone: "тел.",
    ChiefInstructor: "Главный инструктор",
    ContactUs: "Контактные данные",
    Dan: "Дан",
    Dojo: "Додзё",
    DojoText_1: "Занятия проводятся в #0#, г.Беер Шева.",
    EitanBehar: "Эйтан Бехар",
    Email: "ел.почта",
    Gallery: "Галерея",
    Ilana: "Илана",
    Info: "Информация",
    InfoText_1: "Для иформации о регистрации просьба обратиться в спортивный центр",
    InfoText_2: "С дополнительной информацией о работе додзё и о распорядке семинаров вам поможет секретарь додзё",
    Kids: "Дети",
    KidsClass: "Детский класс",
    LessonsTaught: "Все уроки проводит",
    Location: "Карта",
    MemberOf_1: "Beer Sheva Aikikai is a member dojo of Беер Шева Айкикай организационно относится",
    MemberOf_2: "с штаб - квартирой в Беркли, Калифорния.",
    Membership: "Членство",
    Mixed: "Взрослые",
    Or: "или",
    OvadiaZagri: "Овадья Загри",
    QualifiedInstructor: "квалифицированный инструктор",
    QualifiedInstructor_1: "квалифицированный инструктор",
    Schedule: "Расписание",
    Seminars: "Семинары",
    SenseiAddress: "Почтовый адрес: г.Мейтар 85025  ул. Цин 37, Израиль",
    Shidoin: "Шидоин",
    SigiRaveh: "Сиги Рева",
    SportsCenterBenGurion: "спортивном центре университета Бен - Гурион",
    SportsCenter: "Спортивный центр",
    Seminar: "Летний семинар",
    SummerSeminar: "Летний семинар",
    SummerSeminar2020: "UnEnKai Летний семинар начнется 18 – 21 июля, 2020 (Четверг - Воскресенье) Все контактные данные можно узнать в Беер Шева Айкикай",
    winterSeminar2022: "UnEnKai Cеминар начнется 19 – 20 февраля, 2020 (Суббота - Воскресенье) Все контактные данные можно узнать в Беер Шева Айкикай, Также необходимо проверить текущие рекомендации по поводу прививки и ношения масок.",
    Sunday: "Воскресенье",
    Tel: "тел.",
    Thursday: "Четверг",
    Tuesday: "Вторник",
    YossiHatzorSensei: "Йоси Хацор Сенсей"	
}
export { Strings_Ru }