import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import './banner.css';
import './banner.mobile.css';


class Banner extends Component {
    static displayName = Banner.name;

    constructor(props) {
        super(props);
    }

    render()
    {
        const { t, i18n } = this.props;

        return (
            <header className="page-header" style={{ background: `url(${process.env.PUBLIC_URL}/images/tori.jpg)`, backgroundSize: "100%", backgroundPosition:"0px -265px"}}>
                <div className="overlay-fluid-block" style={{ backgroundColor: "rgba(0, 0, 0, 0.2)"}}>
                    <div className="row">
                        <div className="home-wrapper">
                            <div className="col-md-10 col-md-offset-1">
                                <img className="aiki-main" src="./images/aiki-white.png" />
                                <div className="home-content">
                                    <h1>{t("BeerShevaAikikai")}</h1>
                                    <p>{t("AikidoSince")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>    
        );
    }
}

export default withTranslation()(Banner);
