import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import Carousel from 'react-bootstrap/Carousel';
import { isMobile } from 'react-device-detect';
import Import from './import';

import './gallery.css';


class Gallery extends Component {
    static displayName = Gallery.name;


    constructor(props) {
        super(props);
        console.log(Import.Images);
    }

    renderGallery() {
        if (isMobile)
            return (
                <div className="row" >
                    {Import.Images.map(image => <img key={image.Name} src={image.Path} className="d-block w-100 pt-4" alt="..." />)}
                </div>
            );
        else
            return (
                <div className="row" style={{ marginTop: "20px" }}>
                    <Carousel className="d-carousel">
                        {Import.Images.map(image => <Carousel.Item key={image.Name}><img className="d-block w-100 pt-4" src={image.Path} alr="..." /></Carousel.Item>)}
                    </Carousel>
                </div>
            );
    }

    render()
    {
        const { t, i18n } = this.props;

        return (
            <section id="gallery">
                <Container>
                    <div className="row">
                        <div className="section-title">
                            <i className="far fa-images"></i>
                            <h3>{t("Gallery")}</h3>
                        </div>
                    </div>

                    {this.renderGallery()}

                </Container>
            </section>
        );
    }
}

export default withTranslation()(Gallery);
