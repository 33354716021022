import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import Dropdown from 'react-bootstrap/Dropdown';
//import FlagIconFactory from 'react-flag-icon-css';

import './header.css';

//const FlagIcon = FlagIconFactory(React, { useCssModules: false });
const languages = {
    en: { nativeName: 'English', icon:"us" },
    he: { nativeName: 'עברית', icon: "il" },
    ru: { nativeName: 'Русский', icon: "ru" }
};

class Header extends Component {
    static displayName = Header.name;

    constructor(props) {
        super(props);
        console.log("HEADER", this.props);
        let lang = window.sessionStorage.getItem("DOJO.LANG") || "en";
        this.props.i18n.changeLanguage(lang);
        this.setDirection(lang);

        this.state = { language: lang };
    }

    switchLanguage = (item, e) => {
        this.props.i18n.changeLanguage(item);
        this.setState({ language: item });
        this.setDirection(item);

        window.sessionStorage.setItem("DOJO.LANG", item);

    }

    setDirection(lang) {
        if (lang == 'he')
            document.documentElement.classList.add('is-rtl');
        else
            document.documentElement.classList.remove('is-rtl');
    }

    render()
    {
        const { t, i18n } = this.props;

        return (
            <nav className="navbar navbar-default navbar-expand-lg navbar-light bg-light fixed-top">
                <Container>
                    <a className="navbar-brand" href="#">
                        <img className="aikido-icon" src="images/aikido-icon.svg" />
                        <div className="aikido-text-box">
                            <img className="aikido-text-icon" src="images/aikido-big.png" />
                            <div className="aikido-text">{t("Aikido")}</div>
                        </div>
                    </a>

                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse">
                        <ul className="nav navbar-nav navbar-right">
                            <li id="dojo-menu-item" className="active"><a jump-target="#dojo">{t("Dojo")}</a></li>
                            <li id="location-menu-item"><a jump-target="#location">{t("Location")}</a></li>
                            <li id="seminars-menu-item"><a jump-target="#seminars">{t("Seminars")}</a></li>
                            <li id="gallery-menu-item"><a jump-target="#gallery">{t("Gallery")}</a></li>
                        </ul>
                    </div>

                    <Dropdown id="language" onSelect={this.switchLanguage}>
                        <Dropdown.Toggle className="btn btn-default dropdown-toggle" variant="default">
                            {/*<span className="v-flag-icon">*/}
                            {/*    <FlagIcon code={languages[this.state.language].icon} />*/}
                            {/*</span>*/}
                            &nbsp;&nbsp;{languages[this.state.language].nativeName}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {Object.keys(languages).map(
                                key =>
                                    <Dropdown.Item key={key} eventKey={key} className="dropdown-item">
                                        {/*<span className="v-flag-icon">*/}
                                        {/*    <FlagIcon code={languages[key].icon} />*/}
                                        {/*</span>*/}
                                        &nbsp;&nbsp;{languages[key].nativeName}
                                    </Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>

                </Container>
            </nav>
        );
    }
}

export default withTranslation()(Header);
