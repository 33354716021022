import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Map } from './map.js';

import './location.css';


class Location extends Component {
    static displayName = Location.name;

    constructor(props) {
        super(props);
    }

    render()
    {
        const { t, i18n } = this.props;

        return (
            <section id="location">
                <Container>
                    <div className="row">
                        <div id="map">
                            <Map />
                        </div>
                        
                    </div>
                </Container>
            </section>    
        );
    }
}

export default withTranslation()(Location);
