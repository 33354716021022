import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import ReactReplaceString from 'react-string-replace';

import './dojo.css';
import './dojo.mobile.css';


class Dojo extends Component {
    static displayName = Dojo.name;

    constructor(props) {
        super(props);
    }

    dan6() {
        if (this.props.i18n.language == "he")
            return <span> {this.props.t("Dan")} 6</span>;
        else
            return <span>6 <sup style={{ marginLeft: "-8px", color: "white" }} ><small>th</small></sup> {this.props.t("Dan")} </span>;
    }

    dan4() {
        if (this.props.i18n.language == "he")
            return <span> {this.props.t("Dan")} 4</span>;
        else
            return <span>4 <sup style={{ marginLeft: "-5px" }}><small>th</small></sup> {this.props.t("Dan")}</span>;
    }

    dan3() {
        if (this.props.i18n.language == "he")
            return <span> {this.props.t("Dan")} 3</span>;
        else
            return <span>3 <sup style={{ marginLeft: "-5px"}}><small>rd</small></sup> {this.props.t("Dan")}</span>;
    }

    dan2() {
        if (this.props.i18n.language == "he")
            return <span> {this.props.t("Dan")} 2</span>;
        else
            return <span>2 <sup style={{ marginLeft: "-3px" }}><small>nd</small></sup> {this.props.t("Dan")}</span>;
    }

    dan1() {
        if (this.props.i18n.language == "he")
            return <span> {this.props.t("Dan")} 1</span>;
        else
            return <span>1 <sup style={{ marginLeft: "-3px"}}><small>st</small></sup> {this.props.t("Dan")}</span>;
    }

    render()
    {
        const { t, i18n } = this.props;

        return (
            <div>
                <section id="dojo" className="light-blue" style={{ marginTop:"-1px"}}>
                    <Container>
                        <div className="row">
                            <div className="head_title text-center">
                                <h3>
                                    {t("ChiefInstructor")}: {t("YossiHatzorSensei")} ,
                                    {this.dan6()}
                                    , {t("Shidoin")}
                                </h3>
                                <h5>{t("SenseiAddress")}</h5>
                            </div>
                        </div>
                    </Container>
                </section>

                <Container style={{ position: "relative" }}>
                    {/*<img src="/images/poster-1.png" className="sensei-foto" />*/ /*IE11 ISSUE*/}
                    <img src="images/poster-2.png" className="sensei-foto" />
                </Container>

                <section id="dojo-second">
                    <a href="#dojo">&nbsp;</a>
                    <div className="container">
                        <div className="row">
                            <div className="dojo-content">
                                <div className="title">
                                    <i className="fal fa-globe"></i>
                                    <h3>{t("Membership")}</h3>
                                </div>
                                <div className="content-text">
                                    {t("MemberOf_1")} <a href="http://www.berkeleyaikikai.org/" target="_blank">Aikido Un En Kai</a> {t("MemberOf_2")} <br />
                                    {ReactReplaceString(t("BerkeleyMemberOf"), "#0#", (match, i) => (<a key={"0"} href="http://www.aikikai.or.jp/eng/index.htm" target="_blank">Hombu Dojo</a>))}
                                </div>
                            </div>


                            <div className="dojo-content">
                                <div className="title">
                                    <i className="fal fa-clock"></i>
                                    <h3>{t("Schedule")}</h3>
                                </div>
                                <div className="content-text">
                                    <table className="schedule">
                                        <tbody>
                                            <tr>
                                                <th></th>
                                                <th>{t("Sunday")}</th>
                                                <th>{t("Tuesday")}</th>
                                                <th>{t("Thursday")}</th>
                                            </tr>
                                            {/*<tr>*/}
                                            {/*    <td>{t("Kids")}</td>*/}
                                            {/*    <td></td>*/}
                                            {/*    <td>16:00 - 17:00</td>*/}
                                            {/*    <td>16:00 - 17:00</td>*/}
                                            {/*</tr>*/}
                                            <tr>
                                                <td>{t("Mixed")}</td>
                                                <td>17:00 - 18:30</td>
                                                <td>17:00 - 18:30</td>
                                                <td>17:00 - 18:30</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="dojo-devider"></div>

                            <div className="dojo-content">
                                <div className="title">
                                    <i className="fal fa-home"></i>
                                    <h3>{t("Dojo")}</h3>
                                </div>
                                <div className="content-text">
                                    {ReactReplaceString(t("DojoText_1"), "#0#", (match, i) => (<a key={"0"} href="http://in.bgu.ac.il/sport/Pages/default.aspx" target="_blank">{t("SportsCenterBenGurion")}</a>))}
                                    {/*<br />*/}
                                    {/*{t("LessonsTaught")} {String(t("ChiefInstructor")).toLowerCase()}: {t("YossiHatzorSensei")}*/}
                                    <br />
                                    {t("AssistantInstructor")}: {t("OvadiaZagri")}, {this.dan4()}, {t("QualifiedInstructor")}
                                    <br />
         {/*                           {t("KidsClass")}: {t("SigiRaveh")}, {this.dan2()}, {t("QualifiedInstructor_1")}*/}
                                </div>
                            </div>

                            <div className="dojo-content">
                                <div className="title">
                                    <i className="fal fa-info-circle"></i>
                                    <h3>{t("Info")}</h3>
                                </div>
                                <div className="content-text">
                                    {t("InfoText_1")}:
                                    <br />
                                    {t("Tel")}: <span className="blue">08-6461789/90</span> - {t("SportsCenter")}
                                    <br />
                                    {t("InfoText_2")}:
                                    <br />
                                    {t("EitanBehar")}, {t("ByEmail")}: <a href="mailto:info@aikido-beersheva.net">info@aikido-beersheva.net</a> {t("Or")} {t("ByPhone")}: <span className="blue">052-852-7181</span>.
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

            </div>    
        );
    }
}

export default withTranslation()(Dojo);
