
export default class Import {

    static importAll(r) {
        let images = {};
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });

        return Object.keys(images).map((key, index) => { return { Name: key, Path: images[key] } });
    }

    static Images = Import.importAll(require.context('./images', false, /\.(png|jpe?g|svg)$/));

}