import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container } from 'reactstrap';

import './seminars.css';


class Seminars extends Component {
    static displayName = Seminars.name;

    constructor(props) {
        super(props);
    }

    render()
    {
        const { t, i18n } = this.props;

        return (
            <section id="seminars">
                <Container>
                    <div className="row">
                        <div className="section-title">
                            <i className="fal fa-graduation-cap"></i>
                            <h3>{t("Seminars")}</h3>
                        </div>
                    </div>

                    <div className="row" style={{ marginTop:"20px"}}>

                        <div className="seminar-content" style={{width:"auto"}}>
                            <div className="title">UnEnKai {t("Seminar")} 2022</div>
                            <div className="content-text">
                                {t("WinterSeminar2022")}
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{ marginTop: "20px" }}>

                        <div className="seminar-content" style={{ width: "auto" }}>
                            <div className="title">UnEnKai {t("SummerSeminar")} 2020</div>
                            <div className="content-text">
                                {t("SummerSeminar2020")}
                            </div>
                        </div>
                    </div>

                </Container>
            </section>
        );
    }
}

export default withTranslation()(Seminars);
