import React, { Component } from 'react';
import GoogleMap from 'google-map-react';

export class Map extends Component {
    static displayName = Map.name;

    constructor(props) {
        super(props);
    }

    renderMarkers(map, maps)
    {
        var contentString = `
            <div style="padding:10px 5px 10px 5px;">
                <p> 
                    <strong style="color: #4381ec; line-height:25px;">Sports Center</strong><br/>
                    Ben Gurion University, Beer-Sheva, Israel
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAMAAABiM0N1AAABEVBMVEUAAAD/////zE3/zE3/zE3/zE3/zE3/zE3/zE3/zE3/zE3/zE3/zE3/zE3/zE3/zE3/zE3/zE3/zE3/zE3/zE3/zE3/zE3/zE3/zE3/zE3/zE3/zE3/zE3/zE3/zE1mRQBnRgFoRwFpSAJqSAJrSQJtSwN3VAh3VAl6Vgp6Vwp9WgyAXA2CXg6DXg6FYRCNZxSOaBSPaRWUbhebdBuddhyedhygeB2heR6nfiGofyGpgCKthCSziSe0iie5jirJnDLNoDTPojXQozXRpDbSpDbUpjfUpjjXqTnYqjnZqzraqzrarDvbrDvgsD3gsT7jtD/ktD/ru0PvvkXwv0XywUf1w0j1xEj9ykz+y0z+y03/zE3+l3AOAAAAH3RSTlMAAAEDDC0vMz5IXmNmiYqNjp2krLPDxNna6Ov09fn8IHm+5AAAAm9JREFUWMPNmGd72jAQgF3HmOUQBwzGLJMUspvZZrZkNV1pErKT+/8/pI6AFiSdLdn60PfjCb0PtqXTnbR3itAUijQM3bRsx/XqAHXPdWzL1NGfhoiMXKkGFLVSzpAUZYoN4NIoZiRE2QqEUMkKilJliKCcEhAZMy2IpGUbUaJ0FYSopsNF+SYI0syHiPRpkKCgY6KpWZBidoov0iU9gUnniqZBmgJPlIcY5FlRuhlH1EzTIqMKsagalGgGYmJPilKtuKJWakJUhtiUx0VZSEB2TFRJIqr8E2XosW/r7fb6BTuHH8/8FRWpkd9tP6B9CWLx4khk0Pl5yydsgVi8YQxFOXrC+8GEedF4bigq0QP+ENF4aSDSa0lFNZ2ITEgqApOIrOQii4hsJj6PvFQsDjYROQDJPj+AQ0QuE79EFiQWB5eIPHYrXCBbBIt7RFSHxNSJCBSgVqTs0bzkIg/5/NK4yIKUxkG2iPzhhmxaaSwkjUhjIolNlmFio1Lt6tL3qIk/llZ5qZZK/su+//EuTHP3yfeXucl/8jj6FeSKzuE9prk/7AaZ5Cf3OKIOyN5b0unsXvE013udt9Ee/4Ckj+z9QSJc+3wzGe9/+TAY2ceObKqIeN4bZfmFnd55/+Hp6aH/tbezMIruPmNFBF3WvB75IRy94mUNU2gddzFN9zis0GJLv9sNvmfjNrz009ide7LCalZOoopRXnn8crY9N26Z2z57iS6P+QX74+nB5mLwurqLmwenj0IFu7oWQtMKipoadW2WssYv+E9ST4e3ouqaY4XterAybTUXCOquNBResii89lF4ERXrauw/u/X7A2rvj0BIWX6tAAAAAElFTkSuQmCC" alt=":)" style="width:26px; padding-left:10px;" />
                </p>
            </div>`;

        let marker = new maps.Marker({
            position: { lat: 31.2612000, lng: 34.8067033 },
            map,
            title: 'Dojo (location)'
        });

        let infowindow = new maps.InfoWindow({
            content: contentString
        });

        infowindow.open(map, marker);
    }

    render()
    {

        return (
            <GoogleMap
                bootstrapURLKeys={{
                    key: "AIzaSyAY2VkeP0cYEjTTvwSGXpZU9k50VZsx3Ck",
                    language: "en",
                    region: "US"
                }}
                defaultCenter={{ lat: 31.2612000, lng: 34.8067033 }}
                defaultZoom={16}
                onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}
            >
            </GoogleMap>
        );
    }
}


