const Strings_He = {
    Aikido: "איקידו	",
    AikidoSince: "איקידו בבאר שבע מאז 1993",	
    AssistantInstructor: "עוזר מדריך",	
    BeerShevaAikikai: "באר שבע איקיקאי	",
    BerkeleyMemberOf: "ומשויך למטה העולמי של איקידו ביפן #0#",
    ByEmail: 'בדא"ל',
    ByPhone: "בטל'",	
    ChiefInstructor:"מדריך ראשי",
    ContactUs: "צור קשר",
    Dan: "דאן",
    Dojo: "דוג׳ו",
    DojoText_1: "השיעורים מתקיימים באולם ההתעמלות ב#0# בבאר - שבע",
    EitanBehar: "איתן בהר",
    Email: "אימייל",
    Gallery: "גלריה",
    Ilana: "אילנה",
    Info: "מידע",
    InfoText_1: "למידע והרשמה, נא, לפנות למשרדים של מרכז הספורט.",
    InfoText_2: "למידע נוסף לגבי פעילויות של הדוג'ו ו/או סדנאות אפשר ליצור קשר עם המזכיר של הדוג'ו",
    Kids: "ילדים",
    KidsClass: "שיעורי ילדים",
    LessonsTaught: "כל השיעורים מועברים על ידי",
    Location: "מיקום",
    MemberOf_1: "באר שבע איקיקאי משתייך ארגונית ל",
    MemberOf_2: "שבסיסה בברקלי קליפורניה.",
    Membership: "חברות",
    Mixed: "בוגרים",
    Or: "או",
    OvadiaZagri: "עובדיה זגרי",
    QualifiedInstructor: "מורה מוסמך לאיקידו",
    QualifiedInstructor_1: "מורה מוסמכת לאיקידו",
    Schedule: "לוח זמנים",
    Seminars: "סדנאות",
    SenseiAddress: "כתובת דואר: צין 37 מיתר 85025",
    Shidoin: "מורה מוסמך לאיקידו(Shidoin)",
    SigiRaveh: "סיגי רווה",
    SportsCenterBenGurion: "מרכז הספורט של אוניברסיטת בן גוריון",
    SportsCenter: "מרכז הספורט",
    Seminar: "סדנא",
    SummerSeminar: "סדנאת קיץ",
    SummerSeminar2020: "UnEnKai סדנאת קיץ יתתקיים ביולי 18 - 21, 2020(יום ראשון - יום חמישי) למידע נוספת אנא פנו לבאר שבע איקיקאי",
    WinterSeminar2022: "UnEnKai סדנא יתתקיים בפברואר 19 - 20, 2022(יום שבת - יום ראשון) למידע נוספת אנא פנו לבאר שבע איקיקאית, ובנוסף נא לבדוק ההנחיות הנוכחיות לגבי חיסונים ומסכות בדוג'ו",
    Sunday: "יום ראשון",
    Sunday: "יום ראשון",
    Tel: "טל",
    Thursday: "יום חמישי",
    Tuesday: "יום שלישי",
    YossiHatzorSensei: "יוסי חצור סנסאיי",
}
export { Strings_He }